import {
  EMethods,
  EPromotionTypes,
  IFilter,
  IFilterOption,
  IState,
} from "store/store.interface";
import {
  PROMOTION_SET_TYPE,
  PROMOTION_SET_FIELDS,
  PROMOTION_GET_FIELDS,
  PROMOTION_SET_VALUE,
  PROMOTION_CREATE,
  PROMOTION_CLEAR_CREATION_FORM,
  PROMOTION_FETCH_CAMPAIGNS,
  PROMOTION_SET_CAMPAIGNS,
  PROMOTION_UPDATE_STATUS,
  PROMOTION_GET_REPORT,
  PROMOTION_DOWNLOAD_BULK_COUPON,
  PROMOTION_UPDATE_PAGE_NUMBER,
  PROMOTION_CLEAR_CAMPAIGNS_LIST,
  PROMOTION_GET_CAMPAIGN_BY_ID,
  PROMOTION_SET_CAMPAIGN_BY_ID,
  PROMOTION_UPDATE_FILTER,
  PROMOTION_RESET_FILTER,
  PROMOTION_ADD_NEW_CAMPAGIN_TO_LIST,
  PROMOTION_SET_SEARCH_TEXT,
  PROMOTION_CLEAR_FIELDS,
  PROMOTION_EXTEND,
  PROMOTION_EXTEND_UPDATE_DATA,
  PROMOTION_UPDATE_CAMPAIGN,
  RESOURCE_GET_BY_ID,
  CREATE_RESOURCE,
  RESOURCE_DATA,
  FILE_UPLOAD_ERROR,
  FILE_UPLOADING,
  PROMOTION_INCREMENTAL_DATA,
} from "../store.types";
import { Dispatch } from "redux";
import { convertArrayOfKeysToObject, downloadCSV, isEmpty } from "utils/app.utils";
import _ from "lodash";
import {
  apiAction,
  replaceUrlVariables,
} from "store/middlewares/api.middleware.helper";
import { endpoints, staticApiEndpoints } from "constant";
import {
  ECampaignObjects,
  ECampaignStatus,
  ExclusionLabel,
  IDashboard,
  IPromotionForm,
  IQuery,
  ISearchQuery,
} from "store/reducers/promotion.reducers";
import { clearBrandAndCategoriesHelpers } from "./config.actions";
import { ERestrictionsSellerType } from "components/PromotionForm/Restrictions/FieldRenderer";

export const setPromotionType = (promotionType: EPromotionTypes) => ({
  type: PROMOTION_SET_TYPE,
  payload: promotionType,
});

export const setSearchTerm = (text: string) => ({
  type: PROMOTION_SET_SEARCH_TEXT,
  payload: text,
});

export const fetchFieldsDetails =
  () => (dispatch: Dispatch, getState: () => IState) => {
    const {
      promotion: {
        creationForm: { type = "" },
      },
    } = getState();
    const endpoint = replaceUrlVariables(staticApiEndpoints.FIELDS, {
      type: type.toLowerCase(),
    });
    dispatch(
      apiAction({
        url: endpoint,
        withToken: true,
        label: PROMOTION_GET_FIELDS,
        data: {},
        isStatic: true,
        method: EMethods.GET,
        onSuccess: ({ data }) => ({
          type: PROMOTION_SET_FIELDS,
          payload: data,
        }),
      })
    );
  };

export const setPromotionObject =
  (value: any, keys?: string[]) =>
  (dispatch: Dispatch, getState: () => IState) => {
    const {
      promotion: { creationForm },
    } = getState();
    let finalObject;
    let formatedAnswer = value;
    const currentValues = { ...creationForm };
    if (!keys || keys.length === 0) {
      finalObject = _.merge(currentValues, formatedAnswer);
    } else {
      finalObject = _.omit(creationForm, keys.join("."));
      if (!isEmpty(value)) {
        const formatedAnswer = convertArrayOfKeysToObject(keys, value);
        finalObject = _.merge(finalObject, formatedAnswer);
      }
    } 
    if (!_.isEqual(finalObject, creationForm))
      dispatch({
        type: PROMOTION_SET_VALUE,
        payload: finalObject,
      });
  };

export const clearCampaingsList = () => ({
  type: PROMOTION_CLEAR_CAMPAIGNS_LIST,
});

export const clearCreationForm = () => ({
  type: PROMOTION_CLEAR_CREATION_FORM,
});

export const clearForm = () => ({
  type: PROMOTION_CLEAR_FIELDS,
});

export const sendCampaignForApproval =
  () => (dispatch: Dispatch, getState: () => IState) => {
    const { promotion: { creationForm }, auth: { name, email },} = getState();
    if(creationForm.restrictions && creationForm.restrictions.bin && creationForm.restrictions.bin.length > 0) {
      let updateForm = creationForm;
      let arr: string[] = [];
      updateForm.restrictions.bin?.map((data: any) => {
        if(typeof data !== 'string') {
          arr.push(data[0])
        } else {
          arr.push(data);
        }
      })
      creationForm.restrictions.bin = arr;
    }

    if (
      creationForm.restrictions &&
      creationForm.restrictions.sku &&
     ((creationForm.restrictions.sku.value && creationForm.restrictions.sku.value.length > 0) ||  creationForm.restrictions.sku.length > 0)
    ) {      
      let updateForm = creationForm.restrictions.sku.value || creationForm.restrictions.sku;
      let arr: string[] = [];
      updateForm.forEach((data: any) => {
        if (typeof data !== "string") {
          arr.push(data[0]);
        } else {
          arr.push(data);
        }
      });
      if (creationForm.restrictions.sku.op === ExclusionLabel.notIn)
        creationForm.restrictions.sku.value = arr;
      else creationForm.restrictions.sku = arr;
    }
    if (
      creationForm.restrictions &&
      creationForm.restrictions.brand &&
      creationForm.restrictions.brand.op === ExclusionLabel.in
    ) {
      creationForm.restrictions.brand = creationForm.restrictions.brand.value;
    }
    if (
      creationForm.restrictions &&
      creationForm.restrictions.category &&
      creationForm.restrictions.category.op === ExclusionLabel.in
    ) {
      creationForm.restrictions.category =
        creationForm.restrictions.category.value;
    }
    let updatedForm = JSON.parse(JSON.stringify(creationForm));
    if (updatedForm?.restrictions?.isCustomerFirstOrder === null) {
      delete updatedForm.restrictions.isCustomerFirstOrder;
    }
    if (updatedForm?.restrictions?.isCustomerFirstOrder) {
      if (updatedForm?.restrictions?.isCustomerFirstOrder === "All Users") {
        delete updatedForm.restrictions.isCustomerFirstOrder;
      }
    }
    updatedForm.type = ([EPromotionTypes.BMSM_CAMPAIGN, EPromotionTypes.BADGE_CAMPAIGN, EPromotionTypes.BIN_CAMPAIGN, EPromotionTypes.SAMPLE_CAMPAIGN].includes(updatedForm?.type)) ? updatedForm.type : updatedForm.couponType;
    delete updatedForm.couponType;
    if(creationForm?.edit) {
      delete updatedForm.schedule;
      delete updatedForm.discount?.rules?.expression;
      delete updatedForm.object;
      delete updatedForm.edit;
      delete updatedForm.status;
      delete updatedForm.id;
      delete updatedForm?.metaData;
      delete updatedForm?.country;
      delete updatedForm?.redemption;
      delete updatedForm?.ruleExpression;
      delete updatedForm?.createdAt;
      delete updatedForm?.updatedAt;
      delete updatedForm?.liveSchedule;
      delete updatedForm?.restrictions.sellerType;
      delete updatedForm?.pausedBy;
      delete updatedForm?.trnHistory;
      if(creationForm?.bankName === null) {
        delete updatedForm.bankName;
      }
      if(creationForm?.type === EPromotionTypes.BULK_COUPON) {
        delete updatedForm.code;
      }
      if(creationForm?.type === EPromotionTypes.BMSM_CAMPAIGN) {
        delete updatedForm.discount?.minQuantity;
      }
    } 
    if(creationForm.couponType === 'BIN_LOCKED_COUPON') {
      updatedForm = {...updatedForm, type: 'DISCOUNT_COUPON'};
    }
    if(creationForm.type === EPromotionTypes.BADGE_CAMPAIGN) {
      if(updatedForm.badge?.sellerType === 'MARKETPLACE') {
        delete updatedForm.badge?.sellerType;
      }else if(updatedForm.badge?.sellerType === 'BOTH'){
        const badge = updatedForm.badge;
        const updatedBadge = {...badge, seller: 'BOTH'};
        updatedForm = {...updatedForm, badge: updatedBadge};
        delete updatedForm.badge?.sellerType;
      }else {
        const badge = updatedForm.badge;
        const updatedBadge = {...badge, seller: 'Carrefour'};
        updatedForm = {...updatedForm, badge: updatedBadge};
        delete updatedForm.badge?.sellerType;
      }
    }
    updatedForm.visibleToRetailCustomer = updatedForm.visibleToRetailCustomer === 'yes';

    dispatch(
      apiAction({
        url: creationForm?.edit ? replaceUrlVariables(endpoints.UPDATE_CAMPAGIN_V2, {
          object: creationForm.object?.toLowerCase(),
          id: creationForm?.id
        }) : endpoints.CREATE_CAMPAIGN,
        withToken: true,
        withStoreId: true,
        data: updatedForm, //(creationForm.type === 'COUPON' && creationForm.couponType !== 'DISCOUNT_COUPON') ? {...updatedForm, type: 'DISCOUNT_COUPON'} : updatedForm,
        label: PROMOTION_CREATE,
        method: creationForm?.edit ? EMethods.PATCH : EMethods.POST,
        onSuccess:
          ({ data }) =>
          (dispatch: Dispatch) => {
            const updatedData = {
              ...data,
              createdByName: name || email,
            };
            if(!creationForm?.edit) {
              dispatch(addNewCampaignToList(updatedData) as any);
            } else {
              dispatch({
                type: PROMOTION_RESET_FILTER,
              });
              dispatch({
                type: PROMOTION_UPDATE_CAMPAIGN,
                payload: updatedData
              })
            }
            dispatch(clearBrandAndCategoriesHelpers());
            dispatch(clearCreationForm());
            setPromotionObject(null, ["restrictions", "customerEmailGroup"])
            dispatch({
              type: RESOURCE_DATA,
              payload: null,
            });
            dispatch({
              type:PROMOTION_INCREMENTAL_DATA,
              payload:null
            })
          },
      })
    );
  };

export const addNewCampaignToList = (campaign: IDashboard) => ({
  type: PROMOTION_ADD_NEW_CAMPAGIN_TO_LIST,
  payload: campaign,
});

export const getCampaignById =
  (id: string, object: string) => (dispatch: Dispatch) => {
    const endpoint = replaceUrlVariables(endpoints.GET_CAMPAGIN_BY_ID_V2, {
      object,
      id,
    });
    dispatch(
      apiAction({
        url: endpoint,
        label: PROMOTION_GET_CAMPAIGN_BY_ID,
        method: EMethods.GET,
        withStoreId: true,
        withToken: true,
        data: {},
        onSuccess:
          ({ data }) =>
          (dispatch: Dispatch) => {
            const {
              restrictions: { isRetail, sellerId },
            } = data;
            // converting boolean to string as our select component doesn't support falsy value in option
            if(_.isBoolean(data?.visibleToRetailCustomer)){
              data.visibleToRetailCustomer = data.visibleToRetailCustomer ? 'yes' : 'no';
            }
            // on basis of seller id we have to set seller type restriction so that
            // it would be visible while editing the campaign
            if (sellerId !== undefined) {
              data.restrictions.sellerType = isRetail
                ? ERestrictionsSellerType.RETAIL
                : ERestrictionsSellerType.MARKETPLACE;
            }
            dispatch(setCampaignById(data, id));
          },
      })
    );
  };

export const getResourceById = (id:string) => (dispatch:Dispatch) => {
  const endPoint = replaceUrlVariables(endpoints.GET_RESOURCE_BY_ID, {id});
    dispatch(
      apiAction({
        url: endPoint,
        label: RESOURCE_GET_BY_ID,
        method: EMethods.GET,
        withStoreId: true,
        withToken: true,
        data: {},
        headers: {
          "Content-Type": "application/octet-stream"
        },
        requestConfig: {
          responseType: "arraybuffer",
        },
        onSuccess: (data) => () => {
          downloadCSV(data, 'customerEmailGroup.csv');
        },
      })
    );
};

export const sendResoures = (data: FormData) => (dispatch:Dispatch) => {
  dispatch({
    type:FILE_UPLOADING,
    payload:true
  })
  const endPoint = endpoints.CREATE_RESOURCE;
  dispatch(apiAction({
    forFileSend: true,
    url: endPoint,
    method: EMethods.POST,
    label: CREATE_RESOURCE,
    withToken: true,
    withStoreId: true,
    data: data,
    onSuccess: ({data}) => (dispatch: Dispatch) =>{
      dispatch({
        type:FILE_UPLOADING,
        payload:false
      })
      dispatch({
        type: RESOURCE_DATA,
        payload: data,
      });
    },
    onFailure:(errorMessage) => (dispatch:Dispatch) =>{
      dispatch({
        type:FILE_UPLOAD_ERROR,
        payload:errorMessage
      })
      
      dispatch({
        type:FILE_UPLOADING,
        payload:false
      })
    } 
  }))
};



export const setCampaignById = (data: IPromotionForm, id?: string) => ({
  type: PROMOTION_SET_CAMPAIGN_BY_ID,
  payload: { ...data, id },
});

const getFiltersValue = (filters?: IFilter[]) => {
  if (filters && filters.length > 0) {
    const formatedFilters = filters
      .filter((filter) => filter.name)
      .map((filter) => {
        const value = filter.value[0];
        if (filter.name === "startDate" || filter.name === "endDate") {
          if (filter.name === "startDate") {
            return `filters=${encodeURIComponent(
              `startDate:ge:${encodeURIComponent(value)}`
            )}`;
          }
          if (filter.name === "endDate") {
            return `filters=${encodeURIComponent(
              `endDate:le:${encodeURIComponent(value)}`
            )}`;
          }
        }
        return `filters=${encodeURIComponent(
          `${filter.name}:eq:${filter.value.join(",")}`
        )}`;
      })
      .join("&");
    return formatedFilters;
  }
};

export const fetchCampaigns =
  (query?: IQuery) => (dispatch: Dispatch, getState: () => IState) => {
    const {
      config: { limit: defaultLimit },
    } = getState();
    const { page, filters, limit } = query || {};
    if (!page) {
      dispatch(clearCampaingsList());
    }
    let endPoint = endpoints.FETCH_CAMPAIGNS;
    const formatedFilters = getFiltersValue(filters);
    if (formatedFilters) {
      endPoint = `${endPoint}?${formatedFilters}`;
    }
    dispatch(
      apiAction({
        url: endPoint,
        method: EMethods.GET,
        label: PROMOTION_FETCH_CAMPAIGNS,
        withStoreId: true,
        withToken: true,
        data: {
          limit: limit || defaultLimit,
          page: page || 0,
        },
        onSuccess: ({ data }) => ({
          type: PROMOTION_SET_CAMPAIGNS,
          payload: data,
        }),
      })
    );
  };

export const searchForCampaigns =
  (query?: ISearchQuery) => (dispatch: Dispatch, getState: () => IState) => {
    const {
      config: { limit: defaultLimit },
    } = getState();
    const { limit, searchTerm, page } = query || {};
    if (!page) {
      dispatch(clearCampaingsList());
    }
    dispatch(
      apiAction({
        url: endpoints.SEARCH_CAMPAIGNS,
        method: EMethods.GET,
        data: {
          name: searchTerm,
          limit: limit || defaultLimit,
          page: page || 0,
        },
        withToken: true,
        withStoreId: true,
        label: PROMOTION_FETCH_CAMPAIGNS,
        onSuccess: ({ data }) => ({
          type: PROMOTION_SET_CAMPAIGNS,
          payload: data,
        }),
      })
    );
  };

export const updatePageNumber = (pageNumber: number) => ({
  type: PROMOTION_UPDATE_PAGE_NUMBER,
  payload: pageNumber,
});

export const updateCampaignStatus =
  (id: string, object: ECampaignObjects, status: ECampaignStatus) =>
  (dispatch: Dispatch) => {
    const endpoint = replaceUrlVariables(endpoints.UPDATE_CAMPAIGN_STATUS, {
      object,
      id,
    });
    dispatch(
      apiAction({
        url: endpoint,
        method: EMethods.PUT,
        label: PROMOTION_UPDATE_STATUS,
        withToken: true,
        withStoreId: true,
        onSuccess: () => ({
          type: PROMOTION_UPDATE_STATUS,
          payload: { id, status },
        }),
        data: {
          status,
        },
      })
    );
  };

export const getDownloadCoupons = (id: string) => (dispatch: Dispatch) => {
  const endpoint = replaceUrlVariables(endpoints.DOWNLOAD_CSV, {id});
  dispatch(
    apiAction({
      url: endpoint,
      method: EMethods.GET,
      label: PROMOTION_DOWNLOAD_BULK_COUPON,
      withToken: true,
      withStoreId: true,
      headers: {
        "Content-Type": "application/octet-stream",
        "Transfer-Encoding": "chunked",
      },
      data: {},
      requestConfig: {
        responseType: "arraybuffer",
      },
      onSuccess: (data) => () => {
        const url = window.URL.createObjectURL(
          new Blob([data], { type: "application/octet-stream" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `bulk_coupon.csv`);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode && link.parentNode.removeChild(link);
      },
    })
  )
}

export const getCampaignReport =
  (id: string, object: ECampaignObjects, name?: string) =>
  (dispatch: Dispatch) => {
    const endpoint = replaceUrlVariables(endpoints.GET_REPORT, { object, id });
    dispatch(
      apiAction({
        url: endpoint,
        method: EMethods.GET,
        label: PROMOTION_GET_REPORT,
        withToken: true,
        withStoreId: true,
        headers: {
          "Content-Type": "application/octet-stream",
          "Transfer-Encoding": "chunked",
        },
        data: {},
        requestConfig: {
          responseType: "arraybuffer",
        },
        onSuccess: (data) => () => {
          const url = window.URL.createObjectURL(
            new Blob([data], { type: "application/octet-stream" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${name || "campaign"}.xlsx`);
          // 3. Append to html page
          document.body.appendChild(link);
          // 4. Force download
          link.click();
          // 5. Clean up and remove the link
          link.parentNode && link.parentNode.removeChild(link);
        },
      })
    );
  };

export const addFilters =
  (filter: IFilterOption) => (dispatch: Dispatch, getState: () => IState) => {
    const {
      promotion: { filters = [] },
    } = getState();
    dispatch(setSearchTerm(""));
    let newFilters = filters;
    const restFilters = filters.filter((option) => option.name !== filter.name);
    if (filter.name === "startDate" || filter.name === "endDate") {
      newFilters = [
        ...restFilters,
        { name: filter.name, value: [filter.value] },
      ];
    } else {
      const filterType = filters.filter(
        (option) => option.name === filter.name
      )[0];
      const isFilterExist =
        filterType && filterType.value.indexOf(filter.value) > -1;
      if (!isFilterExist) {
        const updatedValue = filterType
          ? [...filterType.value, filter.value]
          : [filter.value];
        newFilters = [
          ...restFilters,
          { name: filter.name, value: updatedValue },
        ];
      }
    }
    dispatch({
      type: PROMOTION_UPDATE_FILTER,
      payload: newFilters,
    });
    dispatch(fetchCampaigns({ filters: newFilters }) as any);
  };

export const removeFilters =
  (filter: IFilterOption) => (dispatch: Dispatch, getState: () => IState) => {
    const {
      promotion: { filters = [] },
    } = getState();
    dispatch(setSearchTerm(""));
    let newFilters = filters;
    const restFilters = filters.filter((option) => option.name !== filter.name);
    if (filter.name === "startDate" || filter.name === "endDate") {
      newFilters = restFilters;
    } else {
      const filterType = filters.filter(
        (option) => option.name === filter.name
      )[0];
      const isFilterExist =
        filterType && filterType.value.indexOf(filter.value) > -1;
      if (isFilterExist) {
        const updatedValues = filterType.value.filter(
          (value) => value !== filter.value
        );
        newFilters = [...restFilters];
        if (updatedValues.length > 0) {
          newFilters.push({
            name: filter.name,
            value: updatedValues,
          });
        }
      }
    }
    dispatch({
      type: PROMOTION_UPDATE_FILTER,
      payload: newFilters,
    });
    dispatch(fetchCampaigns({ filters: newFilters }) as any);
  };

export const resetFilters = () => (dispatch: Dispatch) => {
  dispatch({
    type: PROMOTION_RESET_FILTER,
  });
  dispatch(fetchCampaigns() as any);
};

export const extendCampaign =
  ({
    endDate,
    campaignId,
    object,
  }: {
    endDate: Date;
    campaignId: string;
    object: string;
  }) =>
  (dispatch: Dispatch) => {
    const url = replaceUrlVariables(endpoints.EXTEND_CAMPAIGN, {
      id: campaignId,
      object,
    });
    dispatch(
      apiAction({
        url,
        method: EMethods.PATCH,
        label: PROMOTION_EXTEND,
        withToken: true,
        withStoreId: true,
        data: {
          endDate,
        },
        onSuccess: ({ data }) => ({
          type: PROMOTION_EXTEND_UPDATE_DATA,
          payload: data,
        }),
      })
    );
  };
