import React, { FC, useCallback, useMemo } from "react";
import { ECampaignStatus, IDashboard } from "store/reducers/promotion.reducers";
import { useStyles } from "./Dashboard.style";
import { Badge } from "custom";
import { isDate } from "date-fns";
import { format } from "date-fns-tz";
import { useDispatch } from "react-redux";
import {
  getCampaignById,
  updateCampaignStatus,
  getCampaignReport,
  getDownloadCoupons,
} from "store/actions/promotion.actions";
import { setLocation } from "store/actions/auth.actions";
import {
  useApiInfo,
  useBadgeInfo,
  usePermissions,
  useTimeZoneSupport,
} from "hooks";
import {
  PROMOTION_GET_REPORT,
  PROMOTION_UPDATE_STATUS,
} from "store/store.types";
import { Box, Chip, SplitButton, Text, Tooltip } from "common";
import DateRange from "@material-ui/icons/DateRange";
import AlarmsOn from "@material-ui/icons/AlarmOn";
import InfoIcon from "@material-ui/icons/Info";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import DiscountIcon from "@material-ui/icons/MoneyOff";
import TotalOrderValueIcon from "@material-ui/icons/AttachMoney";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { EPromotionTypes } from "store/store.interface";

export interface IProps {
  promotion: IDashboard;
  listing: any;
}

interface IProgressItem {
  title: string;
  type: "orderValue" | "discountValue" | "redemptionCount";
  value: number;
  listing?: any;
}

const ProgressItem: React.FC<IProgressItem> = ({
  title,
  type,
  value,
  listing,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { progressHilightText, progressIcon, progressWrapper } = useStyles({});
  const IconComponent = useMemo(() => {
    switch (type) {
      case "redemptionCount":
        return DataUsageIcon;
      case "orderValue":
        return TotalOrderValueIcon;
      default:
        return DiscountIcon;
    }
  }, [type]);
  if (isMobile || listing === "grid") {
    return (
      <Tooltip title={title} withHelpIcon={false}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          mr="10px"
          my={["10px", "0px"]}
        >
          <Box
            display="flex"
            alignItems="center"
            alignContent="center"
            flexDirection="row"
            className={progressWrapper}
          >
            <IconComponent color="primary" className={progressIcon} />
            <Text
              fontWeight={600}
              fontSize={["12px", "13px", "15px"]}
              className={progressHilightText}
            >
              <span>{value}</span>
            </Text>
          </Box>
        </Box>
      </Tooltip>
    );
  }
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      mr="10px"
      data-testid="progress-value-container"
    >
      <Box
        display="flex"
        alignItems="center"
        alignContent="center"
        flexDirection="row"
        className={progressWrapper}
      >
        <IconComponent color="primary" className={progressIcon} />
        <Text
          fontWeight={600}
          fontSize={["12px", "13px", "15px"]}
          className={progressHilightText}
          data-testid={`${type}-progress-value`}
        >
          {title} <span>{value}</span>
        </Text>
      </Box>
    </Box>
  );
};

const OverFlowText: React.FC<any> = ({ text, ...rest }) => {
  return (
    <Tooltip title={text}>
      <Text {...rest}>{text}</Text>
    </Tooltip>
  );
};

const PromotionItem: FC<IProps> = ({ promotion, listing }) => {
  const {
    type,
    status,
    startDate,
    endDate,
    description,
    name,
    id,
    object,
    redeemedAmount,
    redeemedCount,
    totalOrdersValue,
  } = promotion;

  const { isLoading } = useApiInfo(PROMOTION_UPDATE_STATUS);
  const { isLoading: isGettingReport } = useApiInfo(PROMOTION_GET_REPORT);
  
  const dispatch = useDispatch();

  const onApprove = useCallback(() => {
    dispatch(updateCampaignStatus(id, object, ECampaignStatus.approved));
  }, [id, object, dispatch]);

  const onComplete = useCallback(() => {
    dispatch(updateCampaignStatus(id, object, ECampaignStatus.completed));
  }, [id, object, dispatch]);

  const onReject = useCallback(() => {
    dispatch(updateCampaignStatus(id, object, ECampaignStatus.rejected));
  }, [id, object, dispatch]);

  const onView = useCallback(() => {
    dispatch(getCampaignById(id, object));
    dispatch(setLocation(`/summary`));
  }, [id, object, dispatch]);

  const onPause = useCallback(() => {
    dispatch(updateCampaignStatus(id, object, ECampaignStatus.paused));
  }, [id, object, dispatch]);

  const onTakeLive = useCallback(()=>{
    dispatch(updateCampaignStatus(id, object, ECampaignStatus.active));
  },[id, object, dispatch]);

  const onViewReport = useCallback(() => {
    dispatch(getCampaignReport(id, object, name));
  }, [dispatch, id, object, name]);

  const onDownloadCoupon = useCallback(() => {
    dispatch(getDownloadCoupons(id))
  }, [dispatch, id]);

  const availableUserActions = usePermissions(status);

  const { dateToTimeZone, timeZone } = useTimeZoneSupport();

  const options = useMemo(() => {
    if (availableUserActions && availableUserActions.length > 0) {
      const availableActions = availableUserActions.map(({ action, title }) => {
        switch (action) {
          case "downloadCoupons": 
              return {
                title: EPromotionTypes.BULK_COUPON === type ? title : "",
                onClick: EPromotionTypes.BULK_COUPON === type ? onDownloadCoupon : onView,
              }
          case "view":
            return {
              title,
              onClick: onView,
            };
          case "approve":
            return {
              title,
              onClick: onApprove,
            };
          case "reject":
            return {
              title,
              onClick: onReject,
            };
          case "getReport":
            return {
              title,
              onClick: onViewReport,
            };
          case "complete":
            return {
              title,
              onClick: onComplete,
            };
          case "pause":
            if((EPromotionTypes.BIN_CAMPAIGN === type || EPromotionTypes.BULK_COUPON === type || EPromotionTypes.COUPON === type || EPromotionTypes.DISCOUNT_COUPON === type || EPromotionTypes.SAMPLE_CAMPAIGN === type)) {
              return {
                title,
                onClick: onPause,
              };
            } else {
              return {
                title: '',
                onClick: onView,
              };
            }
          case "takeLive":
            return{
              title,
              onClick:onTakeLive,
            }  
          default:
            return {
              title: "",
              onClick: onView,
            };
        }
      });
      return availableActions;
    }
    return [];
  }, [
    availableUserActions,
    onApprove,
    onReject,
    onView,
    onViewReport,
    onDownloadCoupon,
    onComplete,
    onPause,
    onTakeLive,
    type
  ]);

  const convertedStartDate = dateToTimeZone(startDate);
  const convertedEndDate = dateToTimeZone(endDate);
  const [formatedStartDate, formatedEndDate] = useMemo(
    () => [
      isDate(convertedStartDate)
        ? format(
            convertedStartDate as Date,
            "MMMM d','yyyy 'at' hh:mm:ss' 'aa XX",
            { timeZone }
          )
        : "",
      isDate(convertedEndDate)
        ? format(
            convertedEndDate as Date,
            "MMMM d','yyyy 'at' hh:mm:ss' 'aa XX",
            { timeZone }
          )
        : "",
    ],
    [convertedEndDate, convertedStartDate, timeZone]
  );

  const { bgColor } = useBadgeInfo(status);
  const { item, icon, dateText, wrapText, overFlow } = useStyles({
    color: bgColor,
    listing,
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={item}
      data-testid="promotion-item-container"
    >
      <Box padding="20px" width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems={["flex-start", "center", "center", "center"]}
          marginY="5px"
          flexDirection={["column", "row", "row", "row"]}
        >
          <Box
            display="flex"
            alignItems={["flex-start", "center", "center", "center"]}
            flexDirection={["column-reverse", "row", "row", "row"]}
            mb={["15px", "0px", "0px", "0px"]}
            flexWrap="wrap"
          >
            <Chip
              data-testid="role-sticker-type"
              borderRadius="5px"
              border={`1px solid #90A0B7`}
              customColor="#90A0B7"
              backgroundColor="#fff"
              fontSize="12px"
              width="auto"
              height="26px"
              margin="0 10px 7px 0"
              padding="5px 20px"
              label={type}
            />
            <OverFlowText
              fontSize="16px"
              fontWeight={600}
              mb="7px"
              className={wrapText}
              text={name}
            />
          </Box>
          <Badge
            as={status}
            variant="contained"
            borderRadius="5px"
            width="120px"
            height="26px"
            margin="0px 0px 7px 0px"
          />
        </Box>
        <Box display="flex" width="100%" alignItems="center" marginY="5px">
          <InfoIcon color="primary" className={icon} />
          <Text marginLeft="10px" className={overFlow}>
            {description}
          </Text>
        </Box>
        <Box display="flex" width="100%" alignItems="center" marginY="5px">
          <DateRange color="primary" className={icon} />
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            marginLeft="10px"
          >
            <Text fontWeight={600} fontSize="13px" className={dateText}>
              <span>Start on</span>
              {formatedStartDate}
            </Text>
          </Box>
        </Box>
        <Box display="flex" my="5px">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            <AlarmsOn color="primary" className={icon} />
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              marginLeft="10px"
            >
              <Text fontWeight={600} fontSize="13px" className={dateText}>
                <span>End on</span>
                {formatedEndDate}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems={["flex-start", "center", "center", "center"]}
          flexDirection={["column", "row", "row", "row"]}
          mt="5px"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            alignContent="center"
          >
            {[ECampaignStatus.active, ECampaignStatus.completed].includes(
              status
            ) ? (
              <>
                <ProgressItem
                  type="redemptionCount"
                  value={redeemedCount}
                  title="Total Redemption"
                  listing={listing}
                />
                <ProgressItem
                  type="discountValue"
                  value={redeemedAmount}
                  title="Total Discount"
                  listing={listing}
                />
                <ProgressItem
                  type="orderValue"
                  value={totalOrdersValue}
                  title="Total Orders Value"
                  listing={listing}
                />
              </>
            ) : (
              <Text
                color="text.secondary"
                fontSize="13px"
                fontWeight={600}
                data-testid="progress-value-default"
                my={["10px", "0px"]}
              >
                You will get information about the campaign progress when live
              </Text>
            )}
          </Box>
          {options && options.length >0 && (
            <Box
              display="flex"
              alignSelf={["flex-start", "flex-end", "flex-end", "flex-end"]}
              mt={["10px", "0px", "0px", "0px"]}
            >
              <SplitButton
                options={options}
                isLoading={isLoading || isGettingReport}
                testId="group-buttons"
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PromotionItem;
